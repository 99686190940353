.exchange-banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 64px;
    background-color: #172b4d;
    z-index: 1000;
    border-bottom: 1px solid #273958;

    .exchange-container {
        height: 100%;
        max-width: 1060px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-x: auto;
        direction: ltr;

        .currency-item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 12px;
            height: 100%;

            .flag {
                position: relative;
                height: 42px;
                width: 42px;
                overflow: hidden;
                border-radius: 20px;
                margin: 0 8px;

                svg {
                    height: 100%;
                    width: 100%;
                    transform: scale(1.37);
                }

                img {
                    height: 42px;
                    width: 42px;
                    object-position: center;
                    object-fit: cover;
                }

                .name {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #00000077;
                    color: #fff;
                    font-size: 16px;
                    font-weight: bold;
                    opacity: 0;
                    transition: 0.2s opacity;
                    user-select: none;
                }
            }

            &:hover {
                .flag {
                    .name {
                        opacity: 1;
                    }
                }
            }

            .price {
                padding: 0 8px;
                line-height: 1.4em;

                .label {
                    color: rgb(245, 190, 88);
                    font-size: 14px;
                }

                .value {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    color: #fff;
                    font-size: 26px;
                    font-weight: bold;

                    .unit {
                        padding: 0 6px;
                        font-size: 10px;
                        color: #d4d4d4;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .exchange-banner {
        .exchange-container {
            justify-content: flex-start;
        }
    }
}

//html[direction="rtl"] {
//    .exchange-banner {
//        direction: rtl;
//
//        .exchange-container {
//            .currency-item .price .label {
//                text-align: right;
//            }
//        }
//    }
//}
