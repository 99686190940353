.how-to-pay-card {
    margin-bottom: 32px;
    min-height: 480px;

    img {
        width: 100%;
        height: 200px;
        object-fit: contain;
        object-position: center center;
        margin-bottom: 16px;
    }
}

.ap-customers {
    .carousel-caption {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 16px 16px 32px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block !important;

        h3 {
            color: #fff;
            text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
        }

        p {
            color: #fff;
        }
    }
}

.press-section-separator {
    svg {
        polygon {
            fill: #037ef3;
        }
    }
}

.press-section {
    background-color: #037ef3;

    .press-card {
        margin-bottom: 32px;

        .description {
            img {
                height: 100px;
                object-fit: contain;
                float: left;
            }
        }
    }
}

html[direction="rtl"] {
    .section-contact-us {
        direction: rtl;
        text-align: right;
    }

    .exchange-section {
        flex-direction: row-reverse;

        .exchange-section-part {
            direction: rtl;
            text-align: right;
        }
    }

    .cards-area {
        direction: rtl;
    }

    .how-to-pay-card {
        margin-bottom: 32px;
        direction: rtl;
        text-align: right;
        min-height: 460px;
    }

    .press-card {
        direction: rtl;
        text-align: right;

        .description {
            img {
                float: right;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .how-to-pay-card {
        margin-bottom: 0;
    }

    .ap-customers {
        .carousel-caption {
            h3 {
                font-size: 13px;
            }

            p {
                font-size: 12px;
            }
        }
    }
}
