/*!

=========================================================
* Argon Design System React - v1.1.0 based on Argon Design System - v1.1.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

@font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    src: url(../font/OpenSans/OpenSans-Light.eot?) format("eot"),
    url(../font/OpenSans/OpenSans-Light.ttf) format("truetype"),
    url(../font/OpenSans/OpenSans-Light.woff) format("woff"),
    url(../font/OpenSans/OpenSans-Light.woff2) format("woff2")
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    src: url(../font/OpenSans/OpenSans-Regular.eot?) format("eot"),
    url(../font/OpenSans/OpenSans-Regular.ttf) format("truetype"),
    url(../font/OpenSans/OpenSans-Regular.woff) format("woff"),
    url(../font/OpenSans/OpenSans-Regular.woff2) format("woff2")
  }
  
  @font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    src: url(../font/OpenSans/OpenSans-Bold.eot?) format("eot"),
    url(../font/OpenSans/OpenSans-Bold.ttf) format("truetype"),
    url(../font/OpenSans/OpenSans-Bold.woff) format("woff"),
    url(../font/OpenSans/OpenSans-Bold.woff2) format("woff2")
  }
  
  @font-face {
    font-family: 'YekanBakh';
    font-weight: 300;
    src: url(../font/YekanBakh/YekanBakhNestedWeb-Light.eot?) format("eot"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Light.ttf) format("truetype"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Light.woff) format("woff"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Light.woff2) format("woff2")
  }
  
  @font-face {
    font-family: 'YekanBakh';
    font-weight: 400;
    src: url(../font/YekanBakh/YekanBakhNestedWeb-Regular.eot?) format("eot"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Regular.ttf) format("truetype"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Regular.woff) format("woff"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Regular.woff2) format("woff2")
  }
  
  @font-face {
    font-family: 'YekanBakh';
    font-weight: 700;
    src: url(../font/YekanBakh/YekanBakhNestedWeb-Bold.eot?) format("eot"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Bold.ttf) format("truetype"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Bold.woff) format("woff"),
    url(../font/YekanBakh/YekanBakhNestedWeb-Bold.woff2) format("woff2")
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: 'YekanBakh', 'OpenSans' !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    font-family: 'YekanBakh', 'OpenSans', 'Arial', sans-serif;
  }
  
// Bootstrap Functions
@import "../../../node_modules/bootstrap/scss/functions";

// Theme Variables
@import "argon-design-system/variables";

// Bootstrap Core
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Theme Core
@import "argon-design-system/theme";

// React Differences
@import "react/react-differences";
