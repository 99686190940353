.header-global {
    .navbar {
        &.headroom--top,
        &.headroom--pinned {
            transform: translateY(64px);
        }
    }
}

.whatsapp-anchor {
    position: fixed;
    bottom: 12px;
    right: 12px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #25d365;
    color: #fff;
    display: flex;
    align-self: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    font-size: 38px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);

    i {
        display: flex;
        align-self: center;
        justify-content: center;
    }
}
