:root {
  --white: #fff;
  --black: #000;
  --ap-blue: #2296f3;
  --ap-blue-dark: #125191;
  --ap-blue-light: #4ca9f5;
  --ap-grey: rgb(200, 217, 223);
  --ap-grey-dark: rgb(141, 141, 141);
  --ap-grey-03: rgb(200, 217, 223, 0.3);
  --ap-bg: #f8f8f8;

  --ap-dark: #212121;
  --ap-dark-light: #424242;
  --ap-dark-border: #808080;
}

.exchange-box-container {
  .card-body {
    padding: 8px;
  }

  .transaction-note {
    margin: 4px 0 6px;
    background-color: var(--ap-grey-03);
    line-height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;

    .inner {
      padding: 6px 0;
      white-space: nowrap;
    }
  }

  .exchange-box-title {
    display: flex;
    align-items: center;
    border-left: 3px solid var(--ap-blue);
    text-align: left;
    padding: 0 16px;
    font-size: 20px;

    .label {
      flex: 1 1;
    }
  }

  .exchange-box-content {
    padding: 16px 24px 16px 16px;

    .input {
      margin: 0;

      fieldset {
        border: none;
      }

      input {
        font-size: 24px;
        font-weight: bold;
      }
    }

    .select {
      fieldset {
        border: none;
        background-color: var(--ap-grey);
        opacity: 0.3;
      }
    }

    .fees-container {
      padding: 0 0 16px;

      .fee-calculation {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 8px;
        text-align: left;
        color: var(--ap-blue);
        cursor: pointer;
      }

      .fee-row {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px 6px;

        &.total-amount {
          font-weight: bold;
        }

        &.ex-border-top {
          position: relative;
          padding-top: 16px;

          &::after {
            position: absolute;
            content: "";
            left: 16px;
            top: 4px;
            width: 312px;
            border-top: 1px solid #aaa;
          }
        }

        .fee-amount {
          font-size: 16px;
          color: #999;
          width: 196px;
          text-align: left;
        }

        .fee-label {
          flex: 1;
          text-align: left;
        }
      }
    }

    .exchange-gap {
      height: 24px;
    }
  }

  .exchange-box-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 0 0 0;

    .exchange-box-button {
      width: 192px;

      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .exchange-box-statement {
    font-size: 16px;
    color: #999;
    flex: 1 1;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

html[direction="rtl"] {
  .exchange-box-container {
    .exchange-box-title {
      text-align: right;
      border: none;
      border-right: 3px solid var(--ap-blue);
      flex-direction: row-reverse;
    }

    .exchange-input-row {
      flex-direction: row;
    }

    .exchange-box-content .fees-container .fee-row {
      &.ex-border-top {
        &::after {
          left: 0;
          width: 100%;
        }
      }

      .fee-amount {
        direction: ltr;
        text-align: left;
        padding: 0 0 0 6px;
      }

      .fee-label {
        text-align: right;
      }
    }

    .exchange-box-footer {
      padding: 24px 0 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .exchange-box-container {
    .exchange-box-title {
      border-left-width: 3px;

      .icon {
        padding: 6px;
      }
    }

    .exchange-box-content {
      padding: 16px 8px 0;

      .select {
        margin-bottom: 12px;

        .exchange-box-select {
          padding: 12px;
        }
      }

      .exchange-input-row {
        > div:first-child {
          margin-bottom: 12px;
        }
      }

      .fees-container {
        .fee-calculation {
          padding: 0 0 8px;
        }

        .fee-row {
          padding: 0 6px 8px;

          &.ex-border-top {
            padding-top: 16px;
            &::after {
              left: 0;
              width: 100%;
            }
          }

          .fee-label {
            font-size: 12px;
          }
        }
      }
    }

    .exchange-box-footer {
      padding: 0;
      flex-direction: column;

      .exchange-box-statement {
        width: 100%;
      }

      .exchange-box-button {
        padding: 12px 0 6px;
        width: 100%;

        button {
          flex: 1;
        }
      }
    }
  }
}
