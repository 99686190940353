.language-modal {
    .language-modal-body {
        text-align: center;

        .language-modal-list {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 0 0;

            .language-modal-item {
                border: 1px solid #aaa;
                padding: 3px;
                border-radius: 5px;
                flex: 1 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row-reverse;
                cursor: pointer;
                margin: 0 8px;

                &:hover {
                    border-color: #777;
                }

                .language-modal-item-text {
                    flex: 1 1;
                }

                svg {
                    height: 32px;
                    border-radius: 4px;
                    overflow: hidden;
                }
            }
        }
    }
}

main {
    margin-top: 64px;
}